import { ChangeEvent, FC, RefObject } from "react";
import styled from "styled-components";

const TextAreaStyle = styled.textarea`
  padding: 8px;
  font-size: 15px;
  border: 2px solid ${({ theme }) => theme.colors.textBright2};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.bgBright1};
  color: ${({ theme }) => theme.colors.textDark1};
  outline: none;
`;

type TextareaProps = {
  name: string;
  value: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  fwdRef?: RefObject<HTMLTextAreaElement>;
  onKeyEnter?: () => void;
  cols: number;
  rows: number;
};

const Textarea: FC<TextareaProps> = ({ fwdRef, ...props }) => {
  return <TextAreaStyle ref={fwdRef} {...props} />;
};

export default Textarea;
