import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreType } from "../store";

const token = process.env.REACT_APP_LOGIN_DISABLED === "true" ? "q" : "";

type AuthStateT = {
  token: string;
};

const initialState: AuthStateT = {
  token,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const authSelector = (state: StoreType) => state.authStore;

export default authSlice.reducer;
