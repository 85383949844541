import { ChangeEvent, FC, KeyboardEvent, RefObject } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setModal } from "../../store/slices/uiSlice";

const InputStyled = styled.input`
  padding: 8px;
  font-size: 15px;
  border: 2px solid ${({ theme }) => theme.colors.textBright2};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.bgBright1};
  color: ${({ theme }) => theme.colors.textDark1};
  outline: none;

  /* &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.active};
  } */

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholderBright};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.placeholderBright};
  }
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.placeholderBright};
  }
`;

type InputProps = {
  name: string;
  value: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fwdRef?: RefObject<HTMLInputElement>;
  onKeyEnter?: () => void;
};

const Input: FC<InputProps> = ({
  name,
  value,
  onChange,
  placeholder,
  fwdRef,
  onKeyEnter,
}) => {
  const dispatch = useDispatch();
  const handleKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyEnter && event.key === "Enter") onKeyEnter();
    if (onKeyEnter && event.key === "Escape") {
      dispatch(setModal(null));
    }
  };

  return (
    <InputStyled
      ref={fwdRef}
      placeholder={placeholder}
      type="text"
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyEnter}
    />
  );
};

export default Input;
