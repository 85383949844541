import styled from "styled-components";
import { CardStyle } from "./Card";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
// import { ReactComponent as ClipboardCopy } from "../../assets/clipboardadd.svg";
import { FC } from "react";
import { uiSelector } from "../../store/slices/uiSlice";
import { useSelector } from "react-redux";

// const CustomClipboardIcon = styled(ClipboardCopy)<{ $dark: boolean }>`
//   height: 24px;
//   width: 24px;
//   margin-bottom: 4px;
//   fill: black;
//   fill: ${({ theme, $dark }) =>
//     $dark ? theme.colors.textBright1 : theme.colors.textBirght2};
//   stroke-width: 0.2;
//   opacity: 0.8;
// `;

const CustomAddIcon = styled(AddIcon)<{ $dark: boolean }>`
  height: 28px;
  width: 28px;
  fill: black;
  fill: ${({ theme, $dark }) =>
    $dark ? theme.colors.textBright1 : theme.colors.textBirght2};
  opacity: 0.8;
`;

type AddItemStylePropsT = {
  $single: boolean;
  $dark: boolean;
  $fade: boolean;
  $disabled: boolean;
};

const AddItemStyle = styled(CardStyle)<AddItemStylePropsT>`
  ${({ $single }) => $single && `grid-column: span 2`};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-left: solid 6px transparent;
  transition: all 0.1s ease-in-out;
  background: ${({ $dark, theme }) =>
    $dark ? theme.colors.bgDark2 : theme.colors.bgBright1};
  ${({ $dark }) => $dark && `box-shadow: none`};

  span {
    color: ${({ $dark, theme }) =>
      $dark ? theme.colors.textBright1 : theme.colors.textDark1};
    font-weight: 600;
    margin-right: 24px;
    opacity: 0.8;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  ${({ $fade, $disabled }) => ($fade || $disabled) && `opacity: 0.5`};
  ${({ $disabled }) => !$disabled && `cursor: pointer`};

  &:hover {
    border-left: 6px solid
      ${({ theme, $disabled }) =>
        $disabled ? "transparent" : theme.colors.warning};

    ${({ $fade, $disabled }) => $fade && !$disabled && `opacity: 1`};
  }
`;

type AddItemProps = {
  onClick: () => void;
  label: string;
  dark?: boolean;
  fade?: boolean;
  single?: boolean;
  disabled?: boolean;
};

const AddItem: FC<AddItemProps> = ({
  onClick,
  label,
  dark = false,
  fade = false,
  single = false,
  disabled = false,
}) => {
  const { darkMode } = useSelector(uiSelector);
  return (
    <AddItemStyle
      $single={single}
      $dark={dark}
      $fade={fade}
      $disabled={disabled}
      onClick={disabled ? undefined : onClick}
    >
      <span>{label}</span>
      {/* <CustomClipboardIcon $dark={dark || darkMode} /> */}
      <CustomAddIcon $dark={dark || darkMode} />
    </AddItemStyle>
  );
};

export default AddItem;
