import { FC } from "react";
import styled from "styled-components";
import { CardStyle } from "../ui/Card";
import Select from "react-select";
import { useSelector } from "react-redux";
import { uiSelector } from "../../store/slices/uiSlice";
import { LanguageCodesT } from "../../types";
import FlagOption from "../ui/FlagOption";
// import FlagDisplay from "../ui/FlagDisplay";

const SelectStyle = styled(Select)`
  flex-grow: 1;
  * {
    cursor: pointer;
  }
`;

const SelectWrapper = styled(CardStyle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 4px;
  color: ${({ theme }) => theme.colors.textDark1};

  & > p {
    margin-right: 16px;
  }

  .dark-select__single-value,
  .dark-select__input-container,
  .dark-select__indicator {
    color: #fff;
  }

  .dark-select__placeholder {
    color: #bbb;
  }

  .dark-select__control {
    border: none;
  }

  .dark-select__control,
  .dark-select__menu {
    background: #444;
    color: #fff;
  }

  .dark-select__option--is-selected {
    background: #777;
    color: #fff;
  }

  .dark-select__option--is-focused {
    background: #555;
    color: #fff;
  }
`;

const SelectCode: FC<{
  isClearable?: boolean;
  options: LanguageCodesT[];
  value: { label: string; value: string };
  onChange: (newCode: any) => void;
}> = ({ isClearable = false, options, onChange, value }) => {
  const { darkMode } = useSelector(uiSelector);

  return (
    <SelectWrapper>
      <p>Language code:</p>
      <SelectStyle
        classNamePrefix={darkMode ? "dark-select" : ""}
        isClearable={isClearable}
        options={options}
        onChange={onChange}
        value={value}
        components={{
          Option: FlagOption,
          // ValueContainer: (props) => <FlagDisplay {...props} lngCode={value} />,
        }}
      />
    </SelectWrapper>
  );
};

export default SelectCode;
