import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputField from "../../components/ui/InputField";
import { authSlice } from "../../store/slices/authSlice";
import { auth } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../hooks/useAuthState";
import loader from "../../assets/loader.gif";

import {
  Wrapper,
  ContentWrapper,
  LoaderImg,
  NotifyStyle,
  ButtonStyled,
  ButtonWrapper,
  LoadingWrapper,
} from "./sharedStyles";

type LoginPropsT = {
  credentials: {
    email: string;
    password: string;
  };
};

const Recover: FC<LoginPropsT> = ({ credentials }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, emailSet] = useState("");
  const [notification, notificationSet] = useState("");

  useEffect(() => {
    if (credentials.email) {
      emailSet(credentials.email);
    }
  }, [credentials]);

  const recoverFn = async (email: string) => {
    const { token } = await auth.recover({
      email,
    });

    dispatch(authSlice.actions.setToken(token));
  };

  const { loading, error, makeRequest } = useAuthState(
    () => recoverFn(email),
    "Failed to login"
  );

  const handleRecover = () => {
    if (!email) return notificationSet("Email is required");

    if (email.length < 2)
      return notificationSet("Email must have more then 2 characters");

    const emailRe = /\S+@\S+\.\S+/;
    if (!emailRe.test(email))
      return notificationSet("Email must be an email: example@test.com");

    notificationSet("");
    makeRequest();
  };

  const handleLogin = () => navigate("/");
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleRecover();
  };

  return (
    <Wrapper>
      <ContentWrapper onSubmit={handleSubmit}>
        <h2>Password reset</h2>
        <InputField
          name="email"
          type="email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            emailSet(e.target.value)
          }
          label="Email"
        />

        <NotifyStyle style={{ opacity: notification || error ? "1" : "0" }}>
          {notification || error}
        </NotifyStyle>

        <ButtonWrapper>
          <ButtonStyled type="submit" onClick={handleRecover}>
            Reset
          </ButtonStyled>
          <ButtonStyled secondary={false} onClick={handleLogin}>
            Go Back
          </ButtonStyled>
        </ButtonWrapper>

        <LoadingWrapper>
          {loading && <LoaderImg src={loader} alt="loader" />}
        </LoadingWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Recover;
