import { identifierThunks, identifierSlice } from "./identifiersSlice";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { StoreType } from "../store";
import * as api from "../../api";
import { setTranslationValue1Code } from "./uiSlice";
import { LanguageCodesT, LanguageCodeT, NamespaceListT } from "../../types";

type NamespaceStateT = {
  namespaces: NamespaceListT[];
  currentNamespaceId: string | null;
  loading: boolean;
  error: null | string;
  lngCodes: LanguageCodesT[];
};

const initialState: NamespaceStateT = {
  namespaces: [],
  currentNamespaceId: null,
  loading: false,
  error: null,
  lngCodes: [],
};

export const namespaceSlice = createSlice({
  name: "namespaces",
  initialState,
  reducers: {
    getNamespaces(state) {
      state.namespaces = [];
      state.loading = true;
      state.error = null;
    },
    setNamespaces(state, action: PayloadAction<NamespaceListT[]>) {
      state.namespaces = action.payload;
      state.loading = false;
      state.error = null;
    },
    handleNamespacesError(state) {
      state.namespaces = [];
      state.loading = false;
      state.error = "Failed to load namespace list";
    },
    setCurrentNamespaceId(state, action: PayloadAction<string | null>) {
      state.currentNamespaceId = action.payload;
    },
    setLngCodes(state, action: PayloadAction<LanguageCodesT[]>) {
      state.lngCodes = action.payload;
    },
  },
});

export const { setCurrentNamespaceId } = namespaceSlice.actions;

export const namespacesSelector = (state: StoreType) => {
  const nsStore = state.namespaceStore;
  const nsQuery = state.uiStore.searchNamespaces;

  return {
    ...nsStore,
    namespaces: nsStore.namespaces.filter((ns) =>
      ns.name.toLocaleLowerCase().includes(nsQuery.toLocaleLowerCase())
    ),
  };
};

const formatedLngListGen = (lngCodes: LanguageCodeT[], usedCodes: string[]) => {
  const usedLngCodes = lngCodes.filter((lnc: LanguageCodeT) =>
    usedCodes.includes(lnc.tag)
  );

  const availableLngCodes = lngCodes.filter(
    (lnc: LanguageCodeT) => !usedCodes.includes(lnc.tag)
  );

  const usedLngOptions = usedLngCodes.map((ulc) => ({
    label: ulc.tag,
    value: ulc.tag,
  }));

  const availableLngOptions = availableLngCodes.map((ulc) => ({
    label: ulc.tag,
    value: ulc.tag,
  }));

  const formated = [
    { label: "used", options: usedLngOptions },
    { label: "available", options: availableLngOptions },
  ];

  return formated;
};

const setStartingLngCode = (lngCodes: LanguageCodeT[]) => {
  const defaultLngCode = process.env.REACT_APP_DEFAULT_CODE || "en-US";

  const findEnglish = lngCodes.find((l: LanguageCodeT) =>
    l.tag.includes(defaultLngCode)
  );
  if (findEnglish) {
    const { tag: englishTag } = findEnglish;
    return setTranslationValue1Code({
      label: englishTag,
      value: englishTag,
    });
  }
  const { tag } = lngCodes[0];
  return setTranslationValue1Code({
    label: tag,
    value: tag,
  });
};

const getNamespaceIdentifiers = (data: NamespaceListT[]) => {
  const namespaceId = data[0]?.id;
  if (namespaceId) {
    return identifierThunks.getNamespaceIdentifiers(namespaceId);
  }
  return identifierSlice.actions.setIdentifiers([]);
};

export const namespaceThunks = {
  loadNamespaces: createAsyncThunk(
    "namespaces/loadNamespaces",
    async (_, { dispatch }) => {
      dispatch(namespaceSlice.actions.getNamespaces());
      try {
        const data = await api.namespace.getList();
        const lngCodes: LanguageCodeT[] = await api.lngCode.getList();
        const usedCodes: string[] = await api.lngCode.getUsedList();
        const formatedLngCodeList = formatedLngListGen(lngCodes, usedCodes);

        dispatch(namespaceSlice.actions.setLngCodes(formatedLngCodeList));
        dispatch(setStartingLngCode(lngCodes));
        dispatch(namespaceSlice.actions.setNamespaces(data));
        dispatch(getNamespaceIdentifiers(data));
      } catch {
        dispatch(namespaceSlice.actions.handleNamespacesError());
      }
    }
  ),

  refreshLngCodeList: createAsyncThunk(
    "namespaces/refreshCodeList",
    async (_, { dispatch }) => {
      try {
        const lngCodes: LanguageCodeT[] = await api.lngCode.getList();
        const usedCodes: string[] = await api.lngCode.getUsedList();
        const formatedLngCodeList = formatedLngListGen(lngCodes, usedCodes);

        dispatch(namespaceSlice.actions.setLngCodes(formatedLngCodeList));
      } catch {
        dispatch(namespaceSlice.actions.handleNamespacesError());
      }
    }
  ),

  getNamespaces: createAsyncThunk(
    "namespaces/getNamespaces",
    async (_, { dispatch }) => {
      dispatch(namespaceSlice.actions.getNamespaces());
      try {
        const dataNamespaces = await api.namespace.getList();
        dispatch(namespaceSlice.actions.setNamespaces(dataNamespaces));
      } catch {
        dispatch(namespaceSlice.actions.handleNamespacesError());
      }
    }
  ),

  removeNamespace: createAsyncThunk<void, string, { state: StoreType }>(
    "namespaces/removeNamespace",
    async (id: string, { dispatch, getState }) => {
      await api.namespace.removeNamespace(id);
      const { currentNamespaceId } = getState().namespaceStore;
      if (currentNamespaceId === id) {
        dispatch(setCurrentNamespaceId(null));
        dispatch(namespaceThunks.loadNamespaces());
      } else {
        dispatch(namespaceThunks.getNamespaces());
      }
    }
  ),
};

export default namespaceSlice.reducer;
