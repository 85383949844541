import { useState } from "react";
import { useHideModal } from "./useHideModal";

type LoadingStateT = {
  loading: boolean;
  error: string | null;
};

type RequestFnT = () => Promise<void>;

export const useLocalState = (requestFn: RequestFnT, failedMsg: string) => {
  const [state, stateSet] = useState<LoadingStateT>({
    loading: false,
    error: null,
  });

  const makeRequest = async () => {
    if (state.loading === true) return;
    stateSet({ loading: true, error: null });
    try {
      const response = await requestFn();
      hideModal();
      return response;
    } catch (e: any) {
      stateSet({
        loading: false,
        error: e?.response?.data?.message ?? failedMsg,
      });
    }
  };

  const { hideModal } = useHideModal();
  const loading = state.loading;
  const error = state.error;

  return { loading, error, makeRequest, hideModal };
};
