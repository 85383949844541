import { FC } from "react";
import styled from "styled-components";

import Flag from "react-world-flags";
import { components } from "react-select";

const FlagStyled = styled(Flag)`
  height: auto;
  width: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
`;

const Text = styled.p`
  text-align: left;
`;

const FlagOption: FC<any> = (props) => {
  const flagCodeSplit = props.label.split("-");
  const flagCode = flagCodeSplit[flagCodeSplit.length - 1];

  const { Option } = components;

  return (
    <Option {...props}>
      <Wrapper>
        <Text>{props.children}</Text>
        <FlagStyled code={flagCode} fallback={<span></span>} />
      </Wrapper>
    </Option>
  );
};

export default FlagOption;
