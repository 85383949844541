import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputField from "../../components/ui/InputField";
import { authSlice } from "../../store/slices/authSlice";
import { auth } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../hooks/useAuthState";
import loader from "../../assets/loader.gif";

import {
  Wrapper,
  ContentWrapper,
  LoaderImg,
  NotifyStyle,
  ButtonStyled,
  ButtonWrapper,
  LoadingWrapper,
} from "./sharedStyles";
import { Link } from "react-router-dom";

const devLoginButton = process.env.REACT_APP_DEV_LOGIN_BUTTON === "true";
const devEmail = process.env.REACT_APP_DEV_EMAIL ?? "";
const devPassword = process.env.REACT_APP_DEV_PASSWORD ?? "";

const signupEnabled = process.env.REACT_APP_SIGN_UP_ENABLED === "true";
const recoverEnabled =
  process.env.REACT_APP_RECOVER_PASSWORD_ENABLED === "true";

type LoginPropsT = {
  credentials: {
    email: string;
    password: string;
  };
};

const Login: FC<LoginPropsT> = ({ credentials }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, emailSet] = useState("");
  const [password, passwordSet] = useState("");
  const [notification, notificationSet] = useState("");

  useEffect(() => {
    if (credentials.email) {
      emailSet(credentials.email);
      passwordSet(credentials.password);
    }
  }, [credentials]);

  const loginFn = async (email: string, password: string) => {
    const { token } = await auth.login({
      email,
      password,
    });

    dispatch(authSlice.actions.setToken(token));
  };

  const { loading, error, makeRequest } = useAuthState(
    () => loginFn(email, password),
    "Failed to login"
  );

  const handleLogin = () => {
    if (!email) return notificationSet("Email is required");

    if (email.length < 2)
      return notificationSet("Email must have more then 2 characters");

    const emailRe = /\S+@\S+\.\S+/;
    if (!emailRe.test(email))
      return notificationSet("Email must be an email: example@test.com");

    if (!password) return notificationSet("Password is required");

    if (password.length < 2)
      return notificationSet("Password must have more then 2 characters");

    notificationSet("");

    makeRequest();
  };

  const handleSignup = () => navigate("/signup");
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <Wrapper>
      <ContentWrapper onSubmit={handleSubmit}>
        <h2>Login</h2>
        <InputField
          name="email"
          type="email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            emailSet(e.target.value)
          }
          label="Email"
        />

        <InputField
          name="password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            passwordSet(e.target.value)
          }
          label="Password"
          type="password"
        />

        {recoverEnabled && (
          <span>
            <Link to="/recover">Forgot password?</Link>
          </span>
        )}
        <NotifyStyle style={{ opacity: notification || error ? "1" : "0" }}>
          {notification || error}
        </NotifyStyle>

        {devLoginButton && (
          <button type="button" onClick={() => loginFn(devEmail, devPassword)}>
            dev login
          </button>
        )}

        <ButtonWrapper $fullWidth={!signupEnabled}>
          <ButtonStyled type="submit" onClick={handleLogin}>
            Login
          </ButtonStyled>
          {signupEnabled && (
            <ButtonStyled secondary={false} onClick={handleSignup}>
              Sign up
            </ButtonStyled>
          )}
        </ButtonWrapper>

        <LoadingWrapper>
          {loading && <LoaderImg src={loader} alt="loader" />}
        </LoadingWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Login;
