import { useSelector } from "react-redux";
import { authSelector } from "../store/slices/authSlice";
import Login from "./authentication/Login";
import Signup from "./authentication/Signup";
import Home from "./Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState } from "react";
import Recover from "./authentication/Recover";

const signupEnabled = process.env.REACT_APP_SIGN_UP_ENABLED === "true";
const recoverEnabled =
  process.env.REACT_APP_RECOVER_PASSWORD_ENABLED === "true";

export type CredentialsT = {
  email: string;
  password: string;
};

const AuthRoutes = () => {
  const [credentials, credentialsSet] = useState<CredentialsT>({
    email: "",
    password: "",
  });

  const saveCredentials = (email: string, password: string) =>
    credentialsSet({ email, password });

  return (
    <Routes>
      {signupEnabled && (
        <Route
          path="/signup"
          element={<Signup saveCredentials={saveCredentials} />}
        />
      )}

      {recoverEnabled && (
        <Route
          path="/recover"
          element={<Recover credentials={credentials} />}
        />
      )}
      <Route path="*" element={<Login credentials={credentials} />} />
    </Routes>
  );
};

const Router = () => {
  const { token } = useSelector(authSelector);
  return <BrowserRouter>{token ? <Home /> : <AuthRoutes />}</BrowserRouter>;
};

export default Router;
