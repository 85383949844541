import { ChangeEvent, FC, KeyboardEvent, RefObject, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { setModal } from "../../store/slices/uiSlice";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import { ReactComponent as EyeOff } from "../../assets/eyeoff.svg";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const InputStyled = styled.input`
  padding: 8px;
  font-size: 15px;
  border: 2px solid ${({ theme }) => theme.colors.borderBright1};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.bgBright1};
  color: ${({ theme }) => theme.colors.textDark1};
  outline: none;
  min-width: 320px;
  width: 360px;
  margin-top: 8px;

  /* &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.active};
  } */

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholderBright};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.placeholderBright};
  }
  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.placeholderBright};
  }
`;

const Label = styled.label`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.textDark1};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

const styleEye = (Icon: any) => styled(Icon)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  fill: none;
  color: ${({ theme }) => theme.colors.textDark2};
  height: auto;
  width: 24px;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 0.9;
  }
`;

const EyeStyled = styleEye(Eye);
const EyeOffStyled = styleEye(EyeOff);

type InputProps = {
  name: string;
  value: string;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fwdRef?: RefObject<HTMLInputElement>;
  onKeyEnter?: () => void;
  label: string;
  type?: string;
};

const InputField: FC<InputProps> = ({
  name,
  value,
  onChange,
  placeholder,
  fwdRef,
  onKeyEnter,
  label,
  type = "text",
}) => {
  const dispatch = useDispatch();
  const [showPassword, showPasswordSet] = useState(false);
  const isPassword = type === "password";
  const ifPassword = showPassword ? "text" : "password";
  const inputType = isPassword ? ifPassword : "text";

  const handleKeyEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyEnter && event.key === "Enter") onKeyEnter();
    if (onKeyEnter && event.key === "Escape") {
      dispatch(setModal(null));
    }
  };

  return (
    <Wrapper>
      {isPassword &&
        (showPassword ? (
          <EyeOffStyled onClick={() => showPasswordSet((old) => !old)} />
        ) : (
          <EyeStyled onClick={() => showPasswordSet((old) => !old)} />
        ))}
      <Label htmlFor={name}>{label}</Label>
      <InputStyled
        ref={fwdRef}
        placeholder={placeholder}
        type={inputType}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyEnter}
        // autoComplete="off"
      />
    </Wrapper>
  );
};

export default InputField;
