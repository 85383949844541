export const brightTheme = {
  colors: {
    placeholderBright: "#888888",
    placeholderDark: "#aaaaaa",
    bgDark1: "#333333",
    bgDark2: "#444444",
    bgDark3: "#555555",
    bgBright1: "#ffffff",
    bgBright2: "#eeeeee",
    textDark1: "#444444",
    textDark2: "#777777",
    textBright1: "#eeeeee",
    textBright2: "#dddddd",
    borderBright1: "#dddddd",
    grey1: "#bbbbbb",
    danger: "#ff4b4b",
    // warning: "#40e5f7",
    // blue: "#25c6d9",
    warning: "#6992ff",
    blue: "#5280fa",
    boxShadow: "0px 5px 5px -3px #99999980",
    // button gradinent
    gradient1fallback: "#1844cc",
    gradient1: "linear-gradient(#537dec, #2e61de)",
    gradient1hover: "linear-gradient(#2e61de, #2e61de)",
    // darkmode button gradient
    gradient2fallback: "#e4edf1",
    gradient2: "linear-gradient(#effaff, #ffffff)",
    // button secondary gradient
    gradient3fallback: "#7d9be9",
    gradient3: "linear-gradient(180deg, #7d9be9ff 0%, #4f77d5ff 100%)",
    gradient3hover: "linear-gradient(#2e61de, #2e61de)",
    // darkmode button gradient
    gradient4fallback: "#e4edf1",
    gradient4: "linear-gradient(#effaff, #ffffff)",

    active: "#42a7ff",
  },
};

export const darkTheme = {
  colors: {
    ...brightTheme.colors,
    bgBright1: "#555555",
    bgBright2: "#444444",
    borderBright1: "transparent",
    textDark1: "#ffffff",
    textDark2: "#999999",
    boxShadow: "none",
    placeholderBright: "#aaaaaa",
    gradient2fallback: "#333333",
    gradient2: "#333333",
  },
};

export type ThemeType = typeof brightTheme;
