import styled from "styled-components";
import TranslationValue from "./TranslationValue";
import IdentifierKey from "./IdentifierKey";
import { FC } from "react";
import AddItem from "../ui/AddItem";
import { ModalTypes, setModal, uiSelector } from "../../store/slices/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import { IdentifierT } from "../../types";

const CustomCard = styled.div`
  margin-bottom: 0px;
  margin-top: 8px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const IdentifierItemStyle = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.textDark1};
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
`;

const IdentifierItem: FC<{ identifier: IdentifierT }> = ({ identifier }) => {
  const dispatch = useDispatch();
  const { translationValue1Code: code1, translationValue2Code: code2 } =
    useSelector(uiSelector);
  if (!code1) return null;

  const value1 = identifier.translations[code1.value];
  const value2 =
    code2 && code2.value ? identifier.translations[code2.value] : "";

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const updateIdentifier = () =>
    dispatch(
      setModal({
        type: ModalTypes.UPDATE_IDENTIFIER,
        key: identifier.key,
        id: identifier.id,
      })
    );

  const removeIdentifier = () =>
    dispatch(
      setModal({
        type: ModalTypes.REMOVE_IDENTIFIER,
        id: identifier.id,
        currentValue: identifier.key,
      })
    );

  const addTranslation = (lngCode: string) =>
    dispatch(
      setModal({
        type: ModalTypes.ADD_TRANSLATION,
        lngCode,
        id: identifier.id,
        translations: identifier.translations,
      })
    );

  const updateTranslation = (lngCode: string, currentValue: string) =>
    dispatch(
      setModal({
        type: ModalTypes.UPDATE_TRANSLATION,
        lngCode,
        id: identifier.id,
        currentValue,
        translations: identifier.translations,
      })
    );

  const removeTranslation = (lngCode: string, currentValue: string) =>
    dispatch(
      setModal({
        type: ModalTypes.REMOVE_TRANSLATION,
        lngCode,
        id: identifier.id,
        currentValue,
        translations: identifier.translations,
      })
    );

  return (
    <CustomCard>
      <IdentifierItemStyle>
        <IdentifierKey
          label={identifier.key}
          onEdit={updateIdentifier}
          onRemove={removeIdentifier}
        />
        {value1 ? (
          <TranslationValue
            single={!code2}
            onEdit={() => updateTranslation(code1.value, value1)}
            onRemove={() => removeTranslation(code1.value, value1)}
            onClipboard={() => copyToClipboard(value1)}
            value={value1}
          />
        ) : (
          <AddItem
            fade={true}
            single={!code2}
            label="Add translation"
            onClick={() => addTranslation(code1.value)}
          />
        )}

        {code2 &&
          (value2 ? (
            <TranslationValue
              onEdit={() => updateTranslation(code2.value, value2)}
              onRemove={() => removeTranslation(code2.value, value2)}
              onClipboard={() => copyToClipboard(value2)}
              value={value2}
            />
          ) : (
            <AddItem
              fade={true}
              label="Add translation"
              onClick={() => addTranslation(code2.value)}
            />
          ))}
      </IdentifierItemStyle>
    </CustomCard>
  );
};

export default IdentifierItem;
