import { FC } from "react";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as RemoveIcon } from "../../assets/trash.svg";
import { ReactComponent as ClipboardIcon } from "../../assets/clipboard.svg";
import { ReactComponent as ClipboardCheckIcon } from "../../assets/clipboard-check.svg";
import styled from "styled-components";

const customIconStyle = (Icon: FC) => styled(Icon)`
  height: 24px;
  width: 24px;
  opacity: 0.6;
`;

export const CustomEditIcon = customIconStyle(EditIcon);
export const CustomRemoveIcon = customIconStyle(RemoveIcon);
export const CustomClipboardIcon = customIconStyle(ClipboardIcon);
export const CustomClipboardCheckIcon = customIconStyle(ClipboardCheckIcon);

export const IconWrapper = styled.div<{ hovercolor: string }>`
  border-left: solid 4px #ddd;
  padding: 4px 6px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-left: solid 4px
      ${({ hovercolor, theme }) => theme.colors[hovercolor]};
  }
`;
