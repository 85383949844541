import { FC } from "react";
import { useDispatch } from "react-redux";
import { useLocalState } from "../../../hooks/useLocalState";
import { namespaceThunks } from "../../../store/slices/namespaceSlice";
import RemoveForm from "../forms/RemoveForm";

const RemoveNamespace: FC<{ id: string; currentValue: string }> = ({
  id,
  currentValue,
}) => {
  const dispatch = useDispatch();
  const removeNamespace = async () => {
    dispatch(namespaceThunks.removeNamespace(id));
  };
  const { loading, error, makeRequest, hideModal } = useLocalState(
    removeNamespace,
    "Failed to remove namespace"
  );

  return (
    <RemoveForm
      label="Remove namespace?"
      error={error}
      loading={loading}
      itemName={currentValue}
      onRemove={makeRequest}
      onCancel={hideModal}
    />
  );
};

export default RemoveNamespace;
