import { FC } from "react";
import styled from "styled-components";

import { ReactComponent as UserIcon } from "../../assets/user.svg";

const CustomUserIcon = styled(UserIcon)`
  stroke: ${({ theme }) => theme.colors.textBright1};
  height: 32px;
  width: 32px;
  opacity: 0.4;
`;

const ButtonStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-top: 8px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.bgDark2};
  color: ${({ theme }) => theme.colors.textBright1};
  border-left: 6px solid transparent;

  &:hover {
    border-left: 6px solid ${({ theme }) => theme.colors.warning};
  }
`;

const ButtonLogout: FC<{
  label: string;
  onClick: () => void;
}> = ({ label, onClick }) => {
  return (
    <ButtonStyle onClick={onClick}>
      <span>{label}</span>
      <CustomUserIcon />
    </ButtonStyle>
  );
};

export default ButtonLogout;
