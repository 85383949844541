import IdentifierItem from "./IdentifierItem";
import styled from "styled-components";
import { identifiersSelector } from "../../store/slices/identifiersSlice";
import { useSelector } from "react-redux";
import DataLoading from "../data/DataLoading";
import DataNotification from "../data/DataNotification";
import { uiSelector } from "../../store/slices/uiSlice";

const ListStyle = styled.div`
  margin: 16px;
  margin-top: 0;
  flex-grow: 1;
  overflow: auto;
`;

const IdentifiersList = () => {
  const { searchIdentifiers } = useSelector(uiSelector);
  const { identifiers, loading, error } = useSelector(identifiersSelector);
  if (loading) return <DataLoading />;

  if (error)
    return <DataNotification description="Failed to reach the server" error />;

  if (searchIdentifiers && identifiers.length === 0)
    return <DataNotification description="No items match your search" />;

  if (identifiers.length === 0)
    return <DataNotification description="No items" />;

  return (
    <ListStyle>
      {identifiers.map((i) => (
        <IdentifierItem identifier={i} key={i.id} />
      ))}
    </ListStyle>
  );
};

export default IdentifiersList;
