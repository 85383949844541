import { FC } from "react";
import styled from "styled-components";
import { CardStyle } from "../ui/Card";
import { CustomEditIcon, CustomRemoveIcon, IconWrapper } from "./StylesMain";

const IdentifierStyle = styled(CardStyle)`
  display: flex;
  align-items: flex-start;

  span {
    padding: 16px;
    flex-grow: 1;
  }
`;

const IdentifierKey: FC<{
  label: string;
  onEdit: () => void;
  onRemove: () => void;
}> = ({ label, onEdit, onRemove }) => {
  return (
    <IdentifierStyle>
      <span>{label}</span>
      <IconWrapper onClick={onEdit} hovercolor="warning">
        <CustomEditIcon />
      </IconWrapper>
      <IconWrapper onClick={onRemove} hovercolor="danger">
        <CustomRemoveIcon />
      </IconWrapper>
    </IdentifierStyle>
  );
};

export default IdentifierKey;
