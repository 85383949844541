import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import { useLocalState } from "../../../hooks/useLocalState";
import { identifierThunks } from "../../../store/slices/identifiersSlice";
import UpdateForm from "../forms/UpdateForm";
import lodash from "lodash";
import { namespacesSelector } from "../../../store/slices/namespaceSlice";

type UpdateIdentifierProps = {
  id: string;
  keyValue: string;
};

const UpdateIdentifier: FC<UpdateIdentifierProps> = ({ keyValue, id }) => {
  const [value, valueSet] = useState(keyValue);
  const dispatch = useDispatch();
  const { currentNamespaceId } = useSelector(namespacesSelector);

  const updateIdentifier = async () => {
    await api.identifier.updateIdentifier(
      id,
      lodash.trimEnd(value),
      currentNamespaceId!
    );
    dispatch(identifierThunks.getIdentifiers());
  };

  const { loading, error, makeRequest, hideModal } = useLocalState(
    updateIdentifier,
    "Failed to update identifier"
  );

  const checkIfChanged = () =>
    lodash.trimEnd(value) === keyValue ? hideModal() : makeRequest();

  return (
    <UpdateForm
      label="Change identifier:"
      inputPlaceholder="new namespace name"
      inputName="namespace-input"
      value={value}
      valueSet={valueSet}
      error={error}
      loading={loading}
      onUpdate={checkIfChanged}
      onCancel={hideModal}
    />
  );
};

export default UpdateIdentifier;
