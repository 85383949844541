import { sortGen } from "./../../util/sortFn";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { StoreType } from "../store";
import * as api from "../../api";
import * as namespaceActions from "./namespaceSlice";
import { SortTypes } from "./uiSlice";
import { IdentifierT } from "../../types";

type IdentifiersStateT = {
  identifiers: IdentifierT[];
  currentIdentifierId: string | null;
  loading: boolean;
  error: null | string;
};

const initialState: IdentifiersStateT = {
  identifiers: [],
  currentIdentifierId: null,
  loading: false,
  error: null,
};

export const identifierSlice = createSlice({
  name: "identifiers",
  initialState,
  reducers: {
    getIdentifiers(state) {
      state.identifiers = [];
      state.loading = true;
      state.error = null;
    },
    setIdentifiers(state, action: PayloadAction<IdentifierT[]>) {
      state.identifiers = action.payload;
      state.loading = false;
      state.error = null;
    },
    handleIdentifiersError(state) {
      state.identifiers = [];
      state.loading = false;
      state.error = "Failed to load identifiers list";
    },
  },
});

export const identifiersSelector = (state: StoreType) => {
  const idStore = state.identifierStore;
  const idQuery = state.uiStore.searchIdentifiers;

  const filteredIdentifiers = idStore.identifiers.filter((ide) =>
    ide.key.toLocaleLowerCase().includes(idQuery.toLocaleLowerCase())
  );

  const { sortBy } = state.uiStore;

  switch (sortBy.value) {
    case SortTypes.BY_CREATED:
      filteredIdentifiers.sort(sortGen("createdAt"));
      break;
    case SortTypes.BY_UPDATED:
      filteredIdentifiers.sort(sortGen("updatedAt"));
      break;
    default:
      filteredIdentifiers.sort(sortGen("key"));
  }

  return {
    ...idStore,
    identifiers: filteredIdentifiers,
  };
};

export const identifierThunks = {
  getNamespaceIdentifiers: createAsyncThunk(
    "identifiers/getIdentifiersList",
    async (namespaceId: string, { dispatch }) => {
      dispatch(identifierSlice.actions.getIdentifiers());

      try {
        const data = await api.namespace.getNamespaceById(namespaceId);
        dispatch(namespaceActions.setCurrentNamespaceId(data.id));
        dispatch(identifierSlice.actions.setIdentifiers(data.identifiers));
      } catch {
        dispatch(identifierSlice.actions.handleIdentifiersError());
      }
    }
  ),

  getIdentifiers: createAsyncThunk<void, void, { state: StoreType }>(
    "identifiers/getIdentifiersList",
    async (_, { dispatch, getState }) => {
      dispatch(identifierSlice.actions.getIdentifiers());

      try {
        const { currentNamespaceId } = getState().namespaceStore;
        const data = await api.namespace.getNamespaceById(currentNamespaceId!);
        dispatch(identifierSlice.actions.setIdentifiers(data.identifiers));
      } catch {
        dispatch(identifierSlice.actions.handleIdentifiersError());
      }
    }
  ),
};

export default identifierSlice.reducer;
