import { ChangeEvent, FC, FormEvent, useState } from "react";
import InputField from "../../components/ui/InputField";
import { auth } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../hooks/useAuthState";
import loader from "../../assets/loader.gif";
import {
  Wrapper,
  LoadingWrapper,
  LoaderImg,
  ContentWrapper,
  NotifyStyle,
  ButtonStyled,
  ButtonWrapper,
} from "./sharedStyles";

type SignupPropsT = {
  saveCredentials: (email: string, password: string) => void;
};

const Signup: FC<SignupPropsT> = ({ saveCredentials }) => {
  const navigate = useNavigate();
  const [email, emailSet] = useState("");
  const [password, passwordSet] = useState("");
  const [notification, notificationSet] = useState("");

  const signupFn = async () => {
    await auth.singup({
      email,
      password,
    });

    saveCredentials(email, password);
    navigate("/");
  };

  const { loading, error, makeRequest } = useAuthState(
    signupFn,
    "Failed to login"
  );

  const handleSignup = () => {
    if (!email) return notificationSet("Email is required");

    if (email.length < 2)
      return notificationSet("Email must have more then 2 characters");

    const emailRe = /\S+@\S+\.\S+/;
    if (!emailRe.test(email))
      return notificationSet("Email must be an email: example@test.com");

    if (!password) return notificationSet("Password is required");

    if (password.length < 2)
      return notificationSet("Password must have more then 2 characters");

    notificationSet("");

    makeRequest();
  };

  const handleBack = () => navigate("/");
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSignup();
  };

  return (
    <Wrapper>
      <ContentWrapper onSubmit={handleSubmit}>
        <h2>Create accout</h2>
        <InputField
          name="email"
          value={email}
          type="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            emailSet(e.target.value)
          }
          label="Email"
        />

        <InputField
          name="password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            passwordSet(e.target.value)
          }
          label="Password"
          type="password"
        />

        <NotifyStyle style={{ opacity: notification || error ? "1" : "0" }}>
          {notification || error}
        </NotifyStyle>

        <ButtonWrapper>
          <ButtonStyled type="submit" onClick={handleSignup}>
            Sign up
          </ButtonStyled>
          <ButtonStyled onClick={handleBack}>Go Back</ButtonStyled>
        </ButtonWrapper>

        <LoadingWrapper>
          {loading && <LoaderImg src={loader} alt="loader" />}
        </LoadingWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Signup;
