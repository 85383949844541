import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import namespaceReducer from "./slices/namespaceSlice";
import identifierReducer from "./slices/identifiersSlice";
import uiReducer from "./slices/uiSlice";
import authReducer from "./slices/authSlice";

const store = configureStore({
  reducer: {
    namespaceStore: namespaceReducer,
    identifierStore: identifierReducer,
    uiStore: uiReducer,
    authStore: authReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type StoreType = ReturnType<typeof store.getState>;

export default store;
