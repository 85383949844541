import { FC } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../api";
import { useLocalState } from "../../../hooks/useLocalState";
import { identifierThunks } from "../../../store/slices/identifiersSlice";
import lodash from "lodash";
import RemoveForm from "../forms/RemoveForm";
import { namespaceThunks } from "../../../store/slices/namespaceSlice";

type UpdateTranslationProps = {
  id: string;
  lngCode: string;
  currentValue: string;
  translations: Record<string, string>;
};

const RemoveTranslation: FC<UpdateTranslationProps> = ({
  id,
  lngCode,
  currentValue,
  translations,
}) => {
  const dispatch = useDispatch();

  const removeTranslation = async () => {
    const newTranslations = lodash.omit(translations, lngCode);
    await api.identifier.updateIdentifierTranslations(id, newTranslations);
    dispatch(identifierThunks.getIdentifiers());
    dispatch(namespaceThunks.refreshLngCodeList());
  };

  const { loading, error, makeRequest, hideModal } = useLocalState(
    removeTranslation,
    "Failed to remove translation"
  );

  return (
    <RemoveForm
      label="Remove translation?"
      error={error}
      loading={loading}
      itemName={currentValue}
      onRemove={makeRequest}
      onCancel={hideModal}
    />
  );
};

export default RemoveTranslation;
