import ControlPanel from "./main/ControlPanel";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import IdentifiersList from "./main/IdentifiersList";

const LayoutStyle = styled.div`
  display: flex;
  height: 100vh;
`;

const MainWrapper = styled.div`
  background: ${({ theme }) => theme.colors.bgBright2};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Layout = () => {
  return (
    <LayoutStyle>
      <Sidebar />
      <MainWrapper>
        <ControlPanel />
        <IdentifiersList />
      </MainWrapper>
    </LayoutStyle>
  );
};

export default Layout;
