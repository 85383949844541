import { ChangeEvent, FC } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as XIcon } from "../../assets/x.svg";

const iconStyleGen = (RC: any, cursor: boolean) => styled(RC)<{ dark: string }>`
  stroke: ${({ theme, dark }) =>
    dark === "true"
      ? theme.colors.placeholderDark
      : theme.colors.placeholderBright};
  height: 28px;
  width: 28px;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  ${cursor ? "cursor: pointer" : ""};
`;

const CustomSearchIcon = iconStyleGen(SearchIcon, false);
const CustomXIcon = iconStyleGen(XIcon, true);

const SearchStyle = styled.div<{ dark: string }>`
  width: 100%;
  max-width: 320px;
  background: ${({ theme, dark }) =>
    dark === "true" ? theme.colors.bgDark2 : theme.colors.bgBright1};
  position: relative;

  input {
    background: none;
    border: none;
    outline: none;
    padding: 10px 18px;
    color: ${({ theme, dark }) =>
      dark === "true" ? theme.colors.textBright1 : theme.colors.textDark1};
    font-size: 14px;
    font-weight: 500;
    width: Calc(100% - 28px);

    ::placeholder {
      color: ${({ theme, dark }) =>
        dark === "true"
          ? theme.colors.placeholderDark
          : theme.colors.placeholderBright};
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: ${({ theme, dark }) =>
        dark === "true"
          ? theme.colors.placeholderDark
          : theme.colors.placeholderBright};
    }
    ::-ms-input-placeholder {
      color: ${({ theme, dark }) =>
        dark === "true"
          ? theme.colors.placeholderDark
          : theme.colors.placeholderBright};
    }
  }
`;

const SearchInput: FC<{
  placeholder: string;
  dark?: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}> = ({ onClear, value, onChange, placeholder, dark = false, ...props }) => {
  return (
    <SearchStyle dark={dark.toString()} {...props}>
      <input
        value={value}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
      />

      {value.length > 0 ? (
        <CustomXIcon onClick={onClear} dark={dark.toString()} />
      ) : (
        <CustomSearchIcon dark={dark.toString()} />
      )}
    </SearchStyle>
  );
};

export default SearchInput;
