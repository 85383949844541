import styled from "styled-components";
import Button from "../../ui/Button";
import Input from "../../ui/Input";
import loader from "../../../assets/loader.gif";
import Textarea from "../../ui/Textarea";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import ButtonExtend from "../../ui/ButtonExtend";

const AddStyle = styled.div`
  display: grid;
  grid-gap: 8px;
  min-width: 360px;
`;

const ControlWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: -8px;
  min-height: 64px;
`;

const LoaderWrapper = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`;

const ButtonExtendStyle = styled(ButtonExtend)`
  margin-right: 16px;
`;

const LoaderImg = styled.img`
  width: auto;
  height: 64;
  margin-right: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  & > button {
    margin-left: 8px;
  }
`;

type AddFormProps = {
  label: string;
  inputPlaceholder: string;
  inputName: string;
  value: string;
  valueSet: (newValue: string) => void;
  error: string | null;
  loading: boolean;
  onAdd: () => void;
  onCancel: () => void;
  extendable?: boolean;
};

const AddForm: FC<AddFormProps> = ({
  label,
  inputPlaceholder,
  inputName,
  value,
  valueSet,
  error,
  loading,
  onAdd,
  onCancel,
  extendable = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [extend, extendSet] = useState(false);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const extendOnLength = value.length > 53;
  const extendOnNewline = value.indexOf("\n") > -1;
  const extendSwitch = extendOnLength || extendOnNewline;

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = value.length;
      textareaRef.current.selectionEnd = value.length;
    }
  }, [extendSwitch, value.length]);

  return (
    <AddStyle>
      <label htmlFor={inputName}>{label}</label>
      {extendSwitch || (extendable && extend) ? (
        <Textarea
          fwdRef={textareaRef}
          placeholder={inputPlaceholder}
          value={value}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            valueSet(e.target.value)
          }
          name={inputName}
          cols={60}
          rows={15}
        />
      ) : (
        <Input
          fwdRef={inputRef}
          placeholder={inputPlaceholder}
          name={inputName}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            valueSet(e.target.value)
          }
          onKeyEnter={onAdd}
        />
      )}
      <p style={{ visibility: error ? "visible" : "hidden" }}>{error}</p>
      <ControlWrapper>
        <LoaderWrapper>
          {!extendSwitch && extendable && (
            <ButtonExtendStyle
              expand={extend}
              onClick={() => extendSet((old) => !old)}
            />
          )}
          {loading && <LoaderImg src={loader} alt="loader" />}
        </LoaderWrapper>
        <ButtonWrapper>
          <Button onClick={onAdd}>Add</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </ButtonWrapper>
      </ControlWrapper>
    </AddStyle>
  );
};

export default AddForm;
