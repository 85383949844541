import loader from "../../assets/loader.gif";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  padding: 23px;
  display: flex;
  justify-content: center;
`;

const DataLoading = ({ ...props }) => {
  return (
    <LoaderWrapper {...props}>
      <img src={loader} alt="loader" />
    </LoaderWrapper>
  );
};

export default DataLoading;
