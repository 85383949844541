import styled from "styled-components";
import Button from "../../ui/Button";
import loader from "../../../assets/loader.gif";
import { FC, useEffect, useRef } from "react";
import lodash from "lodash";

const RemoveStyle = styled.div`
  display: grid;
  grid-gap: 8px;
  min-width: 360px;
  max-width: 500px;
  overflow: hidden;
`;

const StyledSelectedItem = styled.p`
  margin-top: 4px;
  font-weight: 600;
  font-size: 1.4em;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  & > button {
    margin-left: 8px;
  }
`;

const LoaderImg = styled.img`
  width: auto;
  height: 64;
  margin-right: auto;
`;

type RemoveFormProps = {
  label: string;
  itemName: string;
  error: string | null;
  loading: boolean;
  onRemove: () => void;
  onCancel: () => void;
};

const RemoveForm: FC<RemoveFormProps> = ({
  label,
  itemName,
  error,
  loading,
  onRemove,
  onCancel,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const truncatedName =
    itemName.length > 50
      ? lodash.trimEnd(itemName.slice(0, 100)) + "..."
      : itemName;

  return (
    <RemoveStyle>
      <div>
        <p>{label}</p>
        <StyledSelectedItem>{truncatedName}</StyledSelectedItem>
      </div>
      <p style={{ visibility: error ? "visible" : "hidden" }}>{error}</p>
      <ButtonWrapper>
        {loading && <LoaderImg src={loader} alt="loader" />}
        <Button onClick={onRemove}>Remove</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </ButtonWrapper>
    </RemoveStyle>
  );
};

export default RemoveForm;
