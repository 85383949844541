import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../api";
import { useLocalState } from "../../../hooks/useLocalState";
import { namespaceThunks } from "../../../store/slices/namespaceSlice";
import UpdateForm from "../forms/UpdateForm";
import lodash from "lodash";

type UpdateNamespaceProps = {
  id: string;
  currentValue: string;
};

const UpdateNamespace: FC<UpdateNamespaceProps> = ({ id, currentValue }) => {
  const [value, valueSet] = useState(currentValue);
  const dispatch = useDispatch();

  const updateNamespace = async () => {
    await api.namespace.updateNamespace(id, lodash.trimEnd(value));
    dispatch(namespaceThunks.getNamespaces());
  };
  const { loading, error, makeRequest, hideModal } = useLocalState(
    updateNamespace,
    "Failed to rename namespace"
  );

  const checkIfChanged = () =>
    lodash.trimEnd(value) === currentValue ? hideModal() : makeRequest();

  return (
    <UpdateForm
      label="Change namespace:"
      inputPlaceholder="new namespace name"
      inputName="namespace-input"
      value={value}
      valueSet={valueSet}
      error={error}
      loading={loading}
      onUpdate={checkIfChanged}
      onCancel={hideModal}
    />
  );
};

export default UpdateNamespace;
