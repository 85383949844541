import { uiSelector } from "../../store/slices/uiSlice";
import { useSelector } from "react-redux";

import ModalWrapper from "./ModalWrapper";
import AddNamespace from "./modals/AddNamespace";
import AddIdentifier from "./modals/AddIdentifier";

import { ModalTypes } from "../../store/slices/uiSlice";
import UpdateNamespace from "./modals/UpdateNamespace";
import RemoveNamespace from "./modals/RemoveNamespace";
import UpdateIdentifier from "./modals/UpdateIdentifier";
import RemoveIdentifier from "./modals/RemoveIdentifier";
import AddTranslation from "./modals/AddTranslation";
import UpdateTranslation from "./modals/UpdateTranslation";
import RemoveTranslation from "./modals/RemoveTranslation";

const ModalController = () => {
  const { modal } = useSelector(uiSelector);
  if (!modal) return null;

  const modals = {
    [ModalTypes.ADD_NAMESPACE]: <AddNamespace />,
    [ModalTypes.UPDATE_NAMESPACE]: (
      <UpdateNamespace id={modal.id} currentValue={modal.currentValue!} />
    ),
    [ModalTypes.REMOVE_NAMESPACE]: (
      <RemoveNamespace id={modal.id} currentValue={modal.currentValue!} />
    ),
    [ModalTypes.ADD_IDENTIFIER]: <AddIdentifier />,
    [ModalTypes.UPDATE_IDENTIFIER]: (
      <UpdateIdentifier id={modal.id} keyValue={modal.key!} />
    ),
    [ModalTypes.REMOVE_IDENTIFIER]: (
      <RemoveIdentifier id={modal.id} currentValue={modal.currentValue!} />
    ),
    [ModalTypes.ADD_TRANSLATION]: (
      <AddTranslation
        id={modal.id}
        lngCode={modal.lngCode!}
        translations={modal.translations!}
      />
    ),
    [ModalTypes.UPDATE_TRANSLATION]: (
      <UpdateTranslation
        id={modal.id}
        lngCode={modal.lngCode!}
        currentValue={modal.currentValue!}
        translations={modal.translations!}
      />
    ),
    [ModalTypes.REMOVE_TRANSLATION]: (
      <RemoveTranslation
        id={modal.id}
        lngCode={modal.lngCode!}
        currentValue={modal.currentValue!}
        translations={modal.translations!}
      />
    ),
  };

  return <ModalWrapper>{modals[modal.type]}</ModalWrapper>;
};

export default ModalController;
