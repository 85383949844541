export const sortGen =
  (sortParam: string) => (a: Record<string, any>, b: Record<string, any>) => {
    const itemA = String(a[sortParam]).toLocaleLowerCase();
    const itemB = String(b[sortParam]).toLocaleLowerCase();

    let comparison = 0;
    if (itemA > itemB) {
      comparison = 1;
    } else if (itemA < itemB) {
      comparison = -1;
    }
    return comparison;
  };
