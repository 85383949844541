import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreType } from "../store";

export enum ModalTypes {
  ADD_NAMESPACE,
  UPDATE_NAMESPACE,
  REMOVE_NAMESPACE,
  ADD_IDENTIFIER,
  UPDATE_IDENTIFIER,
  REMOVE_IDENTIFIER,
  ADD_TRANSLATION,
  UPDATE_TRANSLATION,
  REMOVE_TRANSLATION,
}

export enum SortTypes {
  BY_NAME,
  BY_CREATED,
  BY_UPDATED,
}

export type SortOption = {
  label: string;
  value: SortTypes;
};

type ModalT = {
  type: ModalTypes;
  id: string;
  currentValue?: string;
  key?: string;
  translations?: Record<string, string>;
  lngCode?: string;
};

type ReactSelectT = { label: string; value: string };

type UiStateT = {
  searchNamespaces: string;
  searchIdentifiers: string;
  translationValue1Code: ReactSelectT | null;
  translationValue2Code: ReactSelectT | null;
  modal: ModalT | null;
  sortBy: SortOption;
  darkMode: boolean;
  fileDrop: boolean;
  files: string[];
};

export const sortOptions: SortOption[] = [
  { label: "Sort by name", value: SortTypes.BY_NAME },
  { label: "Sort by time created", value: SortTypes.BY_CREATED },
  { label: "Sort by time updated", value: SortTypes.BY_UPDATED },
];

const initialState: UiStateT = {
  searchNamespaces: "",
  searchIdentifiers: "",
  translationValue1Code: null,
  translationValue2Code: null,
  modal: null,
  sortBy: sortOptions[0],
  darkMode: false,
  fileDrop: false,
  files: [],
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSearchNamespaces(state, action: PayloadAction<string>) {
      state.searchNamespaces = action.payload;
    },
    setSearchIdentifiers(state, action: PayloadAction<string>) {
      state.searchIdentifiers = action.payload;
    },
    setTranslationValue1Code(state, action: PayloadAction<ReactSelectT>) {
      state.translationValue1Code = action.payload;
    },
    setTranslationValue2Code(state, action: PayloadAction<ReactSelectT>) {
      state.translationValue2Code = action.payload;
    },
    setModal(state, action: PayloadAction<Partial<ModalT> | null>) {
      if (action.payload === null) {
        state.modal = null;
      } else {
        state.modal = {
          type: action.payload.type!,
          id: action.payload.id ?? "",
          currentValue: action.payload.currentValue ?? "",
          key: action.payload.key ?? "",
          translations: action.payload.translations ?? undefined,
          lngCode: action.payload.lngCode ?? "",
        };
      }
    },
    setSortBy(state, action: PayloadAction<SortOption>) {
      state.sortBy = action.payload;
    },
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
      localStorage.setItem("darkMode", JSON.stringify(state.darkMode));
    },
    setDarkMode(state, action: PayloadAction<boolean>) {
      state.darkMode = action.payload;
    },
    setFileDrop(state, action: PayloadAction<boolean>) {
      state.fileDrop = action.payload;
    },
  },
});

export const {
  setSearchNamespaces,
  setSearchIdentifiers,
  setTranslationValue1Code,
  setTranslationValue2Code,
  setModal,
  setSortBy,
} = uiSlice.actions;

export const uiSelector = (state: StoreType) => state.uiStore;

export const lngCode1Selector = (state: StoreType) => {
  const lngCodes = state.namespaceStore.lngCodes;
  const lngCodesUsed = lngCodes && lngCodes[0] ? lngCodes[0].options : [];
  const lngCodesAvailable = lngCodes && lngCodes[1] ? lngCodes[1].options : [];
  const code2 = state.uiStore.translationValue2Code?.value;
  const filteredUsed = lngCodesUsed.filter((lcu) => lcu.value !== code2);
  const filteredAvailable = lngCodesAvailable.filter(
    (lcu) => lcu.value !== code2
  );
  const lngSelectList1 = [
    { label: "used", options: filteredUsed },
    { label: "available", options: filteredAvailable },
  ];

  return { lngSelectList1 };
};

export const lngCode2Selector = (state: StoreType) => {
  const lngCodes = state.namespaceStore.lngCodes;
  const lngCodesUsed = lngCodes && lngCodes[0] ? lngCodes[0].options : [];
  const lngCodesAvailable = lngCodes && lngCodes[1] ? lngCodes[1].options : [];
  const code1 = state.uiStore.translationValue1Code?.value;
  const filteredUsed = lngCodesUsed.filter((lcu) => lcu.value !== code1);
  const filteredAvailable = lngCodesAvailable.filter(
    (lcu) => lcu.value !== code1
  );
  const lngSelectList2 = [
    { label: "used", options: filteredUsed },
    { label: "available", options: filteredAvailable },
  ];

  return { lngSelectList2 };
};

export default uiSlice.reducer;
