import { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../api";
import { useLocalState } from "../../../hooks/useLocalState";
import { identifierSlice } from "../../../store/slices/identifiersSlice";
import {
  namespaceThunks,
  namespaceSlice,
} from "../../../store/slices/namespaceSlice";
import AddForm from "../forms/AddForm";
import lodash from "lodash";

const AddNamespace = () => {
  const [value, valueSet] = useState("");
  const dispatch = useDispatch();

  const addNamespace = async () => {
    const { id } = await api.namespace.addNamespace(lodash.trimEnd(value));
    dispatch(namespaceThunks.getNamespaces());
    dispatch(namespaceSlice.actions.setCurrentNamespaceId(id));
    dispatch(identifierSlice.actions.setIdentifiers([]));
  };

  const { loading, error, makeRequest, hideModal } = useLocalState(
    addNamespace,
    "Failed to add namespace"
  );

  return (
    <AddForm
      label="Add new namespace:"
      inputPlaceholder="new namespace"
      inputName="namespace-input"
      value={value}
      valueSet={valueSet}
      error={error}
      loading={loading}
      onAdd={makeRequest}
      onCancel={hideModal}
    />
  );
};

export default AddNamespace;
