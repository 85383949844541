import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import { namespacesSelector } from "../../../store/slices/namespaceSlice";
import { useLocalState } from "../../../hooks/useLocalState";
import { identifierThunks } from "../../../store/slices/identifiersSlice";
import lodash from "lodash";
import AddForm from "../forms/AddForm";

const AddIdentifier = () => {
  const [value, valueSet] = useState("");
  const dispatch = useDispatch();
  const { currentNamespaceId } = useSelector(namespacesSelector);

  const addIdentifier = async () => {
    await api.identifier.addIdentifier(
      lodash.trimEnd(value),
      currentNamespaceId!
    );
    dispatch(identifierThunks.getIdentifiers());
  };

  const { loading, error, makeRequest, hideModal } = useLocalState(
    addIdentifier,
    "Failed to add identifier"
  );

  return (
    <AddForm
      label="Add new identifier:"
      inputPlaceholder="new identifier"
      inputName="identifier-input"
      value={value}
      valueSet={valueSet}
      error={error}
      loading={loading}
      onAdd={makeRequest}
      onCancel={hideModal}
    />
  );
};

export default AddIdentifier;
