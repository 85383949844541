import _axios from "axios";
import {
  IdentifierT,
  LanguageCodeT,
  NamespaceListT,
  NamespaceT,
} from "../types";

const baseURL = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";

const axios = _axios.create({
  baseURL,
  timeout: 5000,
});

export const namespace = {
  getList: (): Promise<NamespaceListT[]> =>
    axios.get("/namespaces/list").then((r) => r.data),

  getNamespaceById: (id: string): Promise<NamespaceT> =>
    axios.get(`/namespaces/${id}`).then((r) => r.data),

  addNamespace: (name: string): Promise<NamespaceT> =>
    axios.post("/namespaces", { name }).then((r) => r.data),

  updateNamespace: (id: string, name: string): Promise<NamespaceT> =>
    axios.patch(`/namespaces/${id}`, { name }),

  removeNamespace: (id: string): Promise<NamespaceT> =>
    axios.delete(`/namespaces/${id}`),
};

export const identifier = {
  addIdentifier: (key: string, namespaceId: string): Promise<IdentifierT> =>
    axios.post("/identifiers", { key, namespaceId }),

  updateIdentifier: (
    id: string,
    key: string,
    namespaceId: string
  ): Promise<IdentifierT> =>
    axios.patch(`/identifiers/${id}`, { key, namespaceId }),

  updateIdentifierTranslations: (
    id: string,
    translations: Record<string, string>
  ): Promise<IdentifierT> =>
    axios.patch(`/identifiers/${id}`, { translations }),

  removeIdentifier: (id: string): Promise<IdentifierT> =>
    axios.delete(`/identifiers/${id}`),
};

export const lngCode = {
  getList: (): Promise<LanguageCodeT[]> =>
    axios.get("/language-codes").then((r) => r.data),
  getUsedList: (): Promise<string[]> =>
    axios.get("/used-codes").then((r) => r.data),
};

export const auth = {
  login: (data: any) => axios.post("/users/login", data).then((r) => r.data),
  singup: (data: any) => axios.post("/users", data).then((r) => r.data),
  recover: (data: any) =>
    axios.post("/users/recover", data).then((r) => r.data),
};

const fileImportConfig = { headers: {} };

export const fileImportExport = {
  // todo: typescript
  importFile: (data: any) => axios.post("/import", data, fileImportConfig),
  exportFile: () => window.open(baseURL + "/export"),
};
