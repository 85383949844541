import { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { GlobalHotKeys } from "react-hotkeys";
import { useDispatch, useSelector } from "react-redux";
import {
  uiSlice,
  ModalTypes,
  setModal,
  uiSelector,
} from "../store/slices/uiSlice";
import Modal from "../components/modal/ModalController";
import Layout from "../components/Layout";
import {
  namespacesSelector,
  namespaceThunks,
} from "../store/slices/namespaceSlice";
import FileDrop from "../components/fileDrop/FileDrop";
import { fileImportExport } from "../api";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(namespaceThunks.loadNamespaces());
  }, [dispatch]);

  // drag and grop file import
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();

      // acceptedFiles.forEach((file: File, index: number) =>
      //   formData.append(`file${index + 1}`, file, file.name)
      // );

      const file = acceptedFiles[0];
      formData.append("file", file, file.name);

      try {
        await fileImportExport.importFile(formData);
      } catch {
        console.log("error importing file");
      }
      dispatch(namespaceThunks.loadNamespaces());
    },
    [dispatch]
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    dispatch(uiSlice.actions.setFileDrop(isDragActive));
  }, [isDragActive, dispatch]);

  const { modal, fileDrop } = useSelector(uiSelector);

  // hotkeys
  const keyMap = {
    ADD_NAMESPACE: "alt+n",
    ADD_IDENTIFIER: "alt+i",
    HIDE_MODAL: "esc",
  };

  const { error, loading, currentNamespaceId } =
    useSelector(namespacesSelector);

  const disableAddNamespace = !!error || !!loading;
  const disabledAddIdentifier = !currentNamespaceId;

  const addNamespace = () => {
    if (!disableAddNamespace) {
      dispatch(setModal({ type: ModalTypes.ADD_NAMESPACE }));
    }
  };

  const addIdentifier = () => {
    if (!disabledAddIdentifier)
      dispatch(setModal({ type: ModalTypes.ADD_IDENTIFIER }));
  };

  const handlers = {
    ADD_NAMESPACE: addNamespace,
    ADD_IDENTIFIER: addIdentifier,
  };

  // import/export control config
  const showImportExprot = process.env.REACT_APP_SHOW_IMPORT_EXPORT === "true";

  return (
    <div>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges={true} />
      <div {...getRootProps()}>
        {showImportExprot && !modal && fileDrop && <FileDrop />}
        <Modal />
        <Layout />
      </div>
    </div>
  );
};

export default Home;
