import { CardStyle } from "../ui/Card";
import SearchInput from "../ui/SearchInput";
import styled from "styled-components";
import AddItem from "../ui/AddItem";
import { useDispatch, useSelector } from "react-redux";
import {
  uiSlice,
  lngCode1Selector,
  lngCode2Selector,
  ModalTypes,
  setModal,
  setTranslationValue1Code,
  setTranslationValue2Code,
  uiSelector,
} from "../../store/slices/uiSlice";
import Select from "react-select";
import SelectCode from "./SelectCode";
import { sortOptions } from "../../store/slices/uiSlice";
import DarkmodeButton from "../sidebar/DarkmodeButton";
import { namespacesSelector } from "../../store/slices/namespaceSlice";
import { ReactSelectT } from "../../types";

const ContorlPanelStyle = styled.div`
  margin: 16px;
`;

const SearchPanelStyle = styled(CardStyle)`
  display: flex;
  align-items: center;
  padding: 4px;

  .dark-select__single-value,
  .dark-select__input-container,
  .dark-select__indicator {
    color: #fff;
  }

  .dark-select__placeholder {
    color: #bbb;
  }

  .dark-select__control {
    border: none;
  }

  .dark-select__control,
  .dark-select__menu {
    background: #444;
    color: #fff;
  }

  .dark-select__option--is-selected {
    background: #777;
    color: #fff;
  }

  .dark-select__option--is-focused {
    background: #555;
    color: #fff;
  }
`;

const SearchInputStyled = styled(SearchInput)`
  border-right: 2px solid ${({ theme }) => theme.colors.grey1};
  margin-right: auto;
`;

const SortSelect = styled(Select)`
  min-width: 300px;
  * {
    cursor: pointer;
  }
`;

const AddIdentifierWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
`;

const ControlPanel = () => {
  const dispatch = useDispatch();
  const addIdentifier = () =>
    dispatch(setModal({ type: ModalTypes.ADD_IDENTIFIER }));

  const setSearchIdentifiers = (newValue: string) =>
    dispatch(uiSlice.actions.setSearchIdentifiers(newValue));

  const {
    translationValue1Code: code1,
    translationValue2Code: code2,
    searchIdentifiers,
    sortBy,
    darkMode,
  } = useSelector(uiSelector);
  const { lngSelectList1 } = useSelector(lngCode1Selector);
  const { lngSelectList2 } = useSelector(lngCode2Selector);

  const changeCode1 = (newCode: ReactSelectT) => {
    dispatch(setTranslationValue1Code(newCode));
  };
  const changeCode2 = (newCode: ReactSelectT) => {
    dispatch(setTranslationValue2Code(newCode));
  };

  const changeSort = (newSort: any) => {
    dispatch(uiSlice.actions.setSortBy(newSort));
  };

  const { currentNamespaceId } = useSelector(namespacesSelector);
  const disabledAddIdentifier = !currentNamespaceId;

  return (
    <ContorlPanelStyle>
      <SearchPanelStyle>
        <SearchInputStyled
          value={searchIdentifiers}
          onChange={(e) => setSearchIdentifiers(e.target.value)}
          onClear={() => setSearchIdentifiers("")}
          placeholder="Search identifiers"
        />

        <DarkmodeButton />
        <SortSelect
          classNamePrefix={darkMode ? "dark-select" : ""}
          onChange={changeSort}
          value={sortBy}
          options={sortOptions}
        />
      </SearchPanelStyle>

      <AddIdentifierWrapper>
        <AddItem
          label="Add Identifier"
          onClick={addIdentifier}
          disabled={disabledAddIdentifier}
        />
        <SelectCode
          value={code1!}
          onChange={changeCode1}
          options={lngSelectList1}
        />
        <SelectCode
          isClearable={true}
          value={code2!}
          onChange={changeCode2}
          options={lngSelectList2}
        />
      </AddIdentifierWrapper>
    </ContorlPanelStyle>
  );
};

export default ControlPanel;
