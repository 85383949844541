import { FC } from "react";
import styled from "styled-components";

const ButtonStyle = styled.button<{ $secondary: boolean }>`
  background: ${({ theme, $secondary }) =>
    $secondary
      ? theme.colors.gradient3fallback
      : theme.colors.gradient1fallback};

  background: ${({ theme, $secondary }) =>
    $secondary ? theme.colors.gradient3 : theme.colors.gradient1};

  color: #eee;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid ${({ theme, $secondary }) => theme.colors.gradient1fallback};

  &:hover {
    background: ${({ theme, $secondary }) => theme.colors.gradient1hover};
  }
`;

const Button: FC<{
  onClick: () => void;
  type?: any;
  secondary?: boolean;
}> = ({ children, onClick, type = "button", secondary = false, ...props }) => {
  return (
    <ButtonStyle
      {...props}
      $secondary={secondary}
      onClick={onClick}
      type={type}
    >
      {children}
    </ButtonStyle>
  );
};

export default Button;
