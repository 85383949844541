import { FC } from "react";
import styled from "styled-components";

export const CardStyle = styled.div`
  background: ${({ theme }) => theme.colors.bgBright1};
  -webkit-box-shadow: ${({ theme }) => theme.colors.boxShadow};
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
`;

const Card: FC = ({ children }) => {
  return <CardStyle>{children}</CardStyle>;
};

export default Card;
