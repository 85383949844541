import styled from "styled-components";
import Button from "../../components/ui/Button";

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.bgBright1};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

export const ContentWrapper = styled.form`
  padding: 32px;
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
  background: ${({ theme }) => theme.colors.bgBright2};
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 500px;

  h2 {
    text-align: center;
    margin-bottom: 32px;
    font-size: 1.5em;
    color: ${({ theme }) => theme.colors.textDark1};
  }

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.warning};
    font-weight: 600;
    cursor: pointer;
    text-align: right;
    margin: 8px 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const NotifyStyle = styled.p`
  color: ${({ theme }) => theme.colors.textDark1};
  min-height: 24px;
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div<{ $fullWidth?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $fullWidth }) =>
    $fullWidth ? "1fr" : "1fr 1fr"};
  grid-gap: 16px;
`;

export const ButtonStyled = styled(Button)`
  margin-top: 16px;
  text-align: center;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 16px;
`;

export const LoaderImg = styled.img`
  width: auto;
  height: 64;
  margin-right: auto;
`;
