import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../api";
import { useLocalState } from "../../../hooks/useLocalState";
import { identifierThunks } from "../../../store/slices/identifiersSlice";
import UpdateForm from "../forms/UpdateForm";
import lodash from "lodash";

type UpdateTranslationProps = {
  id: string;
  lngCode: string;
  currentValue: string;
  translations: Record<string, string>;
};

const UpdateTranslation: FC<UpdateTranslationProps> = ({
  id,
  lngCode,
  currentValue,
  translations,
}) => {
  const [value, valueSet] = useState(currentValue);
  const dispatch = useDispatch();

  const updateTranslation = async () => {
    await api.identifier.updateIdentifierTranslations(id, {
      ...translations,
      [lngCode]: lodash.trimEnd(value),
    });
    dispatch(identifierThunks.getIdentifiers());
  };

  const { loading, error, makeRequest, hideModal } = useLocalState(
    updateTranslation,
    "Failed to update translation"
  );

  const checkIfChanged = () =>
    lodash.trimEnd(value) === currentValue ? hideModal() : makeRequest();

  return (
    <UpdateForm
      label="Change translation:"
      inputPlaceholder="new namespace name"
      inputName="namespace-input"
      value={value}
      valueSet={valueSet}
      error={error}
      loading={loading}
      onUpdate={checkIfChanged}
      onCancel={hideModal}
      extendable
    />
  );
};

export default UpdateTranslation;
