import { FC } from "react";
import styled from "styled-components";

import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";

const customIconStyle = (Icon: FC) => styled(Icon)`
  stroke: ${({ theme }) => theme.colors.textBright1};
  height: 32px;
  width: 32px;
  opacity: 0.4;
`;

const CustomDownloadIcon = customIconStyle(DownloadIcon);
const CustomUploadIcon = customIconStyle(UploadIcon);

const ButtonStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-top: 8px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.bgDark2};
  color: ${({ theme }) => theme.colors.textBright1};
  border-left: 6px solid transparent;

  &:hover {
    border-left: 6px solid ${({ theme }) => theme.colors.warning};
  }
`;

const ButtonCsv: FC<{
  label: string;
  upload?: boolean;
  onClick: () => void;
}> = ({ label, upload = false, onClick }) => {
  return (
    <ButtonStyle onClick={onClick}>
      <span>{label}</span>
      {upload ? <CustomUploadIcon /> : <CustomDownloadIcon />}
    </ButtonStyle>
  );
};

export default ButtonCsv;
