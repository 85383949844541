import { FC } from "react";
import styled from "styled-components";

import { ReactComponent as XIcon } from "../../assets/x.svg";
import { useHideModal } from "../../hooks/useHideModal";

const CustomXIcon = styled(XIcon)<{ dark: string }>`
  stroke: ${({ theme, dark }) =>
    dark === "true"
      ? theme.colors.placeholderDark
      : theme.colors.placeholderBright};
  height: 28px;
  width: 28px;
`;

const WrapperStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    z-index: 1001;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.bgDark1};
    opacity: 0.7;
  }
`;

const ModalStyle = styled.div`
  background: ${({ theme }) => theme.colors.bgBright1};
  z-index: 1100;
`;

const ModalHeader = styled.div`
  background: ${({ theme }) => theme.colors.bgDark1};
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  position: relative;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.bgDark1};
  border-radius: 4px;
  display: flex;
  align-items: center;

  transition: 0.1s;

  &:hover {
    transform: scale(1.15);
  }

  &:active {
    transform: scale(1);
  }
`;

const ModalContent = styled.div`
  padding: 24px;
  padding-bottom: 16px;
  background: ${({ theme }) => theme.colors.bgBright2};
  color: ${({ theme }) => theme.colors.textDark1};
`;

const ModalWrapper: FC = ({ children }) => {
  const { hideModal } = useHideModal();
  return (
    <WrapperStyle>
      <ModalStyle>
        <ModalHeader>
          <CloseButton onClick={hideModal}>
            <CustomXIcon dark="true" />
          </CloseButton>
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
      </ModalStyle>
    </WrapperStyle>
  );
};

export default ModalWrapper;
