import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { CardStyle } from "../ui/Card";
import {
  CustomRemoveIcon,
  CustomEditIcon,
  CustomClipboardIcon,
  IconWrapper,
  CustomClipboardCheckIcon,
} from "./StylesMain";

const ValueStyle = styled(CardStyle)<{ $single: boolean }>`
  display: flex;
  align-items: flex-start;
  grid-column: span ${({ $single }) => ($single ? 2 : 1)};
  white-space: pre-line;

  span {
    padding: 16px;
    flex-grow: 2;
  }
`;

type TranslationValueProps = {
  single?: boolean;
  onEdit: () => void;
  onRemove: () => void;
  onClipboard: () => void;
  value: string;
};

const TranslationValue: FC<TranslationValueProps> = ({
  single = false,
  onEdit,
  onRemove,
  onClipboard,
  value,
}) => {
  const [check, checkSet] = useState(false);

  const onToClipboard = () => {
    checkSet(true);
    onClipboard();
  };

  useEffect(() => {
    let timer1: ReturnType<typeof setTimeout>;
    if (check) {
      timer1 = setTimeout(() => checkSet(false), 2000);
    }

    return () => {
      if (timer1) {
        clearTimeout(timer1);
      }
    };
  }, [check]);

  return (
    <ValueStyle $single={single}>
      <span>{value}</span>
      <IconWrapper onClick={onToClipboard} hovercolor="warning">
        {check ? <CustomClipboardCheckIcon /> : <CustomClipboardIcon />}
      </IconWrapper>
      <IconWrapper onClick={onEdit} hovercolor="warning">
        <CustomEditIcon />
      </IconWrapper>
      <IconWrapper onClick={onRemove} hovercolor="danger">
        <CustomRemoveIcon />
      </IconWrapper>
    </ValueStyle>
  );
};

export default TranslationValue;
