import { FC } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../api";
import { useLocalState } from "../../../hooks/useLocalState";
import { identifierThunks } from "../../../store/slices/identifiersSlice";
import RemoveForm from "../forms/RemoveForm";

const RemoveIdentifier: FC<{ id: string; currentValue: string }> = ({
  id,
  currentValue,
}) => {
  const dispatch = useDispatch();
  const removeIdentifier = async () => {
    await api.identifier.removeIdentifier(id);
    dispatch(identifierThunks.getIdentifiers());
  };
  const { loading, error, makeRequest, hideModal } = useLocalState(
    removeIdentifier,
    "Failed to remove identifier"
  );

  return (
    <RemoveForm
      label="Remove identifier?"
      error={error}
      loading={loading}
      itemName={currentValue}
      onRemove={makeRequest}
      onCancel={hideModal}
    />
  );
};

export default RemoveIdentifier;
