import { FC } from "react";
import styled from "styled-components";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";

const IconStyle = (icon: FC) => styled(icon)<{ $dark: boolean }>`
  fill: transparent;
  stroke: ${({ $dark, theme }) => ($dark ? "#bbb" : theme.colors.textDark2)};
  width: auto;
  height: 48px;
`;

const StyledErrorIcon = IconStyle(ErrorIcon);
const StyledInfoIcon = IconStyle(InfoIcon);

const NotifyWrapper = styled.div<{ $dark: boolean }>`
  color: ${({ $dark, theme }) => ($dark ? "#bbb" : theme.colors.textDark2)};
  background: ${({ $dark, theme }) =>
    $dark ? "#444" : theme.colors.bgBright2};
  padding: 32px;
  margin: ${({ $dark }) => ($dark ? "16px 0" : "16px")};
  font-size: ${({ $dark }) => ($dark ? "1.1em" : "1.5em")};

  display: flex;
  justify-content: center;
  flex-direction: column;

  & > p {
    margin-top: 16px;
    text-align: center;
  }
`;

const DataNotification: FC<{
  description: string;
  dark?: boolean;
  error?: boolean;
}> = ({ description, dark = false, error = false, ...props }) => {
  return (
    <NotifyWrapper $dark={dark} {...props}>
      {error ? (
        <StyledErrorIcon $dark={dark} />
      ) : (
        <StyledInfoIcon $dark={dark} />
      )}
      <p>{description}</p>
    </NotifyWrapper>
  );
};

export default DataNotification;
