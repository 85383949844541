import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  ModalTypes,
  setModal,
  uiSelector,
  uiSlice,
} from "../store/slices/uiSlice";
import AddItem from "./ui/AddItem";
import ButtonCsv from "./sidebar/ButtonCsv";
import ButtonLogout from "./sidebar/ButtonLogout";
import NamespaceList from "./sidebar/NamespaceList";
import SearchInput from "./ui/SearchInput";
import { fileImportExport } from "../api";
import {
  namespacesSelector,
  namespaceThunks,
} from "../store/slices/namespaceSlice";
import { ChangeEvent, useEffect, useState, useRef } from "react";
import { authSlice } from "../store/slices/authSlice";

const authDisabled = process.env.REACT_APP_LOGIN_DISABLED === "true";

const SearchInputStyled = styled(SearchInput)`
  margin-bottom: 20px;
  margin-top: 4px;
`;

const SidebarStyle = styled.div`
  background: #333;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
`;

const Sidebar = () => {
  const dispatch = useDispatch();
  const { searchNamespaces } = useSelector(uiSelector);
  const [selectedFile, selectedFileSet] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const addNamespace = () =>
    dispatch(setModal({ type: ModalTypes.ADD_NAMESPACE }));

  const setSearchNamespaces = (newValue: string) =>
    dispatch(uiSlice.actions.setSearchNamespaces(newValue));

  const handleImport = () => {
    console.log("file import");
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleExport = () => {
    fileImportExport.exportFile();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      selectedFileSet(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      (async () => {
        const fd = new FormData();
        fd.append("file", selectedFile, selectedFile?.name);

        try {
          await fileImportExport.importFile(fd);
        } catch {
          console.log("error importing file");
        }
        dispatch(namespaceThunks.loadNamespaces());
      })();
    }
  }, [selectedFile, dispatch]);

  const showImportExprot = process.env.REACT_APP_SHOW_IMPORT_EXPORT === "true";

  const { error, loading } = useSelector(namespacesSelector);
  const disableAddNamespace = !!error || !!loading;

  const handleLogout = () => {
    dispatch(authSlice.actions.setToken(""));
  };

  return (
    <SidebarStyle>
      <SearchInputStyled
        onChange={(e) => setSearchNamespaces(e.target.value)}
        onClear={() => setSearchNamespaces("")}
        value={searchNamespaces}
        dark={true}
        placeholder="Search namespaces"
      />
      <AddItem
        dark={true}
        label="Add namespace"
        onClick={addNamespace}
        disabled={disableAddNamespace}
      />
      <NamespaceList />
      {!authDisabled && <ButtonLogout label="Logout" onClick={handleLogout} />}
      {showImportExprot && (
        <>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <ButtonCsv
            onClick={handleImport}
            upload={true}
            label="Import from Csv"
          />
          <ButtonCsv onClick={handleExport} label="Export to Csv" />
        </>
      )}
    </SidebarStyle>
  );
};

export default Sidebar;
