import { FC } from "react";
import styled from "styled-components";
import { ReactComponent as CollapseIcon } from "../../assets/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../assets/expand.svg";

const CollapseIconStyled = styled(CollapseIcon)``;
const ExpandIconStyled = styled(ExpandIcon)``;

const ButtonExtendStyle = styled.div`
  background: ${({ theme }) => theme.colors.gradient1fallback};
  background: ${({ theme }) => theme.colors.gradient1};
  color: #eee;
  cursor: pointer;
  padding: 6px 16px 2px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.colors.gradient1fallback};

  &:hover {
    background: ${({ theme }) => theme.colors.gradient1hover};
  }
`;

const ButtonExtend: FC<{
  onClick: () => void;
  expand: boolean;
}> = ({ expand, onClick, ...props }) => {
  return (
    <ButtonExtendStyle {...props} onClick={onClick}>
      {expand ? <CollapseIconStyled /> : <ExpandIconStyled />}
    </ButtonExtendStyle>
  );
};

export default ButtonExtend;
