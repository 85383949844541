import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { uiSelector, uiSlice } from "./store/slices/uiSlice";
import { brightTheme, darkTheme } from "./theme";
import Router from "./router/Router";
import { useEffect } from "react";

const App = () => {
  const { darkMode } = useSelector(uiSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const darkModeLocal: boolean = JSON.parse(
      localStorage.getItem("darkMode") ?? "false"
    );
    dispatch(uiSlice.actions.setDarkMode(darkModeLocal));
  }, [dispatch]);

  return (
    <ThemeProvider theme={!!darkMode ? darkTheme : brightTheme}>
      <Router />
    </ThemeProvider>
  );
};

export default App;
