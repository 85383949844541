import styled from "styled-components";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";

const StyledUploadIcon = styled(UploadIcon)`
  width: auto;
  height: 56px;
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 16px;
`;

const DropWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #279eff4b;
  z-index: 1000;
`;

const FileDropStyle = styled.div`
  background: ${({ theme }) => theme.colors.bgBright2};
  color: ${({ theme }) => theme.colors.textDark1};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: dashed 2px ${({ theme }) => theme.colors.blue};
  border-radius: 20px;
  padding: 40px;
  min-width: 500px;
  font-size: 1.4em;
  display: flex;
  flex-direction: column;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: 700;
  }
`;

const FileDrop = () => {
  return (
    <DropWrapper>
      <FileDropStyle>
        <StyledUploadIcon />
        <p>
          Drop you zip, csv file <span>here</span>
        </p>
      </FileDropStyle>
    </DropWrapper>
  );
};

export default FileDrop;
