import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { uiSelector, uiSlice } from "../../store/slices/uiSlice";

import { ReactComponent as SunIcon } from "../../assets/sun.svg";
import { ReactComponent as MoonIcon } from "../../assets/moon.svg";

const styleIcon = (SvgIcon: any) => styled(SvgIcon)`
  fill: none;
  color: ${({ theme }) => theme.colors.textDark1};
  height: auto;
  width: 28px;
  position: absolute;
`;

const StyledMoon = styleIcon(MoonIcon);
const StyledSun = styleIcon(SunIcon);

const DarkmodeButtonStyle = styled.div`
  height: 32px;
  width: 56px;
  position: relative;
  opacity: 0.7;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 32px;
  background: ${({ theme }) => theme.colors.bgBright2};
  margin-right: 16px;

  -webkit-box-shadow: inset 0px -3px 15px rgba(56, 56, 56, 0.2);
  box-shadow: inset 0px -3px 15px rgba(56, 56, 56, 0.2);

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.8;
  }
`;

const Knob = styled.div<{ $darkMode: boolean }>`
  border-radius: 32px;
  display: flex;
  align-items: center;
  padding: 4px;
  margin: -3px 0;
  width: 36px;
  height: 32px;
  background: ${({ theme }) => theme.colors.bgBright1};
  box-shadow: 2px 2px 5px #33333366;
  position: absolute;
  left: 0;

  transition: all 0.2s;
  transform: translateX(${({ $darkMode }) => ($darkMode ? "60%" : "0")});
`;

const DarkmodeButton = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(uiSlice.actions.toggleDarkMode());
  const { darkMode } = useSelector(uiSelector);

  return (
    <DarkmodeButtonStyle onClick={handleClick}>
      <Knob $darkMode={darkMode}>
        <StyledSun $show={darkMode} style={{ opacity: darkMode ? "1" : "0" }} />
        <StyledMoon
          $show={darkMode}
          style={{ opacity: darkMode ? "0" : "1" }}
        />
      </Knob>
    </DarkmodeButtonStyle>
  );
};

export default DarkmodeButton;
