import { FC } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as RemoveIcon } from "../../assets/trash.svg";
import { identifierThunks } from "../../store/slices/identifiersSlice";
import { setModal, ModalTypes } from "../../store/slices/uiSlice";
import { NamespaceListT } from "../../types";

const styledIcon = (Icon: FC) => styled(Icon)`
  width: 24px;
  height: 24px;
`;

const CustomEditIcon = styledIcon(EditIcon);
const CustomRemoveIcon = styledIcon(RemoveIcon);

const NamespaceStyle = styled.div<{ current: string }>`
  background: ${({ theme }) => theme.colors.bgDark2};
  color: ${({ theme }) => theme.colors.textBright2};
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;

  span {
    padding: 16px 24px;
    flex-grow: 1;
    border-left: 6px solid
      ${({ theme, current: selected }) =>
        selected === "true" ? theme.colors.blue : "transparent"};

    &:hover {
      border-left: 6px solid ${({ theme }) => theme.colors.warning};
    }

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
`;

const IconWrapper = styled.div<{ hovercolor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  border-left: solid 4px ${({ theme }) => theme.colors.bgDark1};
  opacity: 0.4;

  &:hover {
    opacity: 1;
    border-left: solid 4px
      ${({ hovercolor, theme }) => theme.colors[hovercolor]};
  }
`;

const NamespaceItem: FC<{ namespace: NamespaceListT; current: boolean }> = ({
  namespace,
  current,
}) => {
  const dispatch = useDispatch();

  const getNamespace = () =>
    dispatch(identifierThunks.getNamespaceIdentifiers(namespace.id));

  const updateNamespace = () =>
    dispatch(
      setModal({
        type: ModalTypes.UPDATE_NAMESPACE,
        id: namespace.id,
        currentValue: namespace.name,
      })
    );

  const removeNamespace = () =>
    dispatch(
      setModal({
        type: ModalTypes.REMOVE_NAMESPACE,
        id: namespace.id,
        currentValue: namespace.name,
      })
    );

  return (
    <NamespaceStyle current={current.toString()}>
      <span onClick={getNamespace}>{namespace.name}</span>

      <IconWrapper hovercolor="warning" onClick={updateNamespace}>
        <CustomEditIcon />
      </IconWrapper>
      <IconWrapper hovercolor="danger" onClick={removeNamespace}>
        <CustomRemoveIcon />
      </IconWrapper>
    </NamespaceStyle>
  );
};

export default NamespaceItem;
