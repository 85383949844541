import NamespaceItem from "./NamespaceItem";
import styled from "styled-components";
import { namespacesSelector } from "../../store/slices/namespaceSlice";
import { useSelector } from "react-redux";
import DataNotification from "../data/DataNotification";
import DataLoading from "../data/DataLoading";
import { uiSelector } from "../../store/slices/uiSlice";

const LoadingStyled = styled(DataLoading)`
  margin-bottom: auto;
`;

const DataNotificationStyled = styled(DataNotification)`
  margin-bottom: auto;
`;

const ListStyle = styled.div`
  margin-top: 16px;
  overflow: auto;
  flex-grow: 1;
`;

const NamespaceList = () => {
  const { namespaces, loading, error, currentNamespaceId } =
    useSelector(namespacesSelector);

  const { searchNamespaces } = useSelector(uiSelector);

  if (loading) return <LoadingStyled />;

  if (error)
    return (
      <DataNotificationStyled
        dark={true}
        description="Failed to reach the server"
        error={true}
      />
    );

  if (searchNamespaces && namespaces.length === 0)
    return (
      <DataNotificationStyled
        dark={true}
        description="No items match your search"
      />
    );

  if (namespaces.length === 0)
    return <DataNotificationStyled dark={true} description="No items" />;

  return (
    <ListStyle>
      {namespaces.map((n) => (
        <NamespaceItem
          current={currentNamespaceId === n.id}
          key={n.id}
          namespace={n}
        />
      ))}
    </ListStyle>
  );
};

export default NamespaceList;
